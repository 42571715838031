<template>
  <div>
    <!-- main banner 2nd -->
    <!-- mobile -->
    <div class="lg:hidden">
      <div class="mx-auto">
        <img src="/images/mobile.jpg" alt="" class="w-full" />
      </div>
      <div class="bg-time-dark">
        <div class="container">
          <div class="py-10 text-center">
            <h1
              class="text-xl font-semibold leading-tight text-black md:text-3xl"
            >
              Keeping You Consistently Connected To Everything That Matters to
              You
            </h1>
            <h1
              class="pt-3 text-lg font-medium leading-tight text-white md:text-2xl"
            >
              Sign Up Now With Malaysia’s <br class="hidden md:block" />
              Most Consistent Broadband Provider
            </h1>
            <div class="pt-5">
              <a
                href="#"
                id="btn-enquire"
                v-scroll-to="{ el: '#element', duration: 1500 }"
              >
                <h1
                  class="py-2 mx-auto text-center text-white bg-black md:text-xl w-44 md:w-48 rounded-xl"
                >
                  Apply Now
                </h1>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- desktop -->
    <div
      class="relative hidden bg-time-bg lg:block"
    >
      <div class="lg:flex">
        <div class="w-2/3">
          <img src="/images/main-banner-2.jpg" alt="" class="" />
        </div>
        <div class="flex items-center w-64 xl:w-80 xl:mr-auto bg-time-dark">
          <div class="px-3 text-center">
            <h1 class="text-lg font-semibold leading-tight text-black">
              Keeping You Consistently Connected To Everything That Matters to
              You
            </h1>
            <h1 class="pt-4 text-base font-medium leading-tight text-white">
              Sign Up Now With Malaysia’s Most Consistent Broadband Provider
            </h1>
            <div class="pt-5">
              <a
                v-if="!hideEnquiry"
                href="#"
                id="btn-enquire"
                v-scroll-to="{ el: '#element', duration: 1500 }"
              >
                <h1
                  class="w-40 py-2 mx-auto text-sm text-center text-white bg-black rounded-xl"
                >
                  Apply Now
                </h1>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.custom-bg {
  position: absolute;
  /* max-height: 600px; */
  width: auto;
  margin: 0 auto;
  left: auto;
  right: 0;
  display: block;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.custom-height {
  min-height: 40vw;
}
@media (min-width: 1024px) {
  .custom-height {
    min-height: 450px;
  }
}
@media (min-width: 1500px) {
  .custom-height {
    min-height: 31vw;
  }
}
</style>