<template>
  <header>
    <div class="bg-time-gray">
      <div class="container">
        <div class="py-3 text-right">
          <a
            href="#"
            id="btn-enquire-top"
            v-scroll-to="{ el: '#element', duration: 1500 }"
          >
            <h1 class="text-xs font-semibold text-white">Apply Now</h1>
          </a>
        </div>
      </div>
    </div>

    <!-- Navigation -->
    <div class="container">
      <div class="flex items-center justify-between py-5">
        <div class="w-3/4 md:w-1/2">
          <img
            src="/images/main-logo.png"
            alt="Vpay Partner Sdn Bhd"
            class="w-44 lg:w-52"
          />
        </div>
        <!-- mobile nav -->
        <div class="flex items-start justify-end w-1/4 md:hidden">
          <div
            @click="opened = !opened"
            class="flex items-center p-2 mt-2 cursor-pointer"
          >
            <h1 class="pr-2 text-sm text-gray-900">MENU</h1>
            <div>
              <svg
                class="w-6 h-6 fill-current text-time-gray"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </div>
          </div>
        </div>

        <!-- desktop nav -->
        <div class="hidden md:block">
          <div class="flex w-1/2 text-lg font-semibold text-time-pink">
            <div class="pr-6 cursor-pointer">
              <a href="" v-scroll-to="{ el: '#packages', duration: 1500 }">
              <h1>Packages</h1>
              </a>
            </div>
            <div class="pr-6 cursor-pointer">
              <a href="" v-scroll-to="{ el: '#install', duration: 1500 }">
              <h1>Installation</h1>
              </a>
            </div>
            <div class="pr-6 cursor-pointer">
              <a href="" v-scroll-to="{ el: '#faq', duration: 1500 }">
              <h1>FAQ</h1>
              </a>
            </div>
            <div class="cursor-pointer">
              <a href="" v-scroll-to="{ el: '#contact', duration: 1500 }">
              <h1>Contact</h1>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- overlay mobile nav -->
      <div v-show="opened" class="bg-time-gray hover:bg-black">
        <div class="text-center">
          <div>
            <a
              href="#"
              v-scroll-to="{ el: '#packages', duration: 1500 }"
              class=""
            >
              <h1 class="py-2 text-white">Packages</h1>
            </a>
          </div>
          <div>
            <a
              href="#"
              v-scroll-to="{ el: '#install', duration: 1500 }"
              class=""
            >
              <h1 class="py-2 text-white">Installation</h1>
            </a>
          </div>
          <div>
            <a href="#" v-scroll-to="{ el: '#faq', duration: 1500 }" class="">
              <h1 class="py-2 text-white">FAQ</h1>
            </a>
          </div>
          <div>
            <a
              href="#"
              v-scroll-to="{ el: '#contact', duration: 1500 }"
              class=""
            >
              <h1 class="py-2 text-white">Contact</h1>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      opened: false,
    };
  },
};
</script>

<style>
</style>