<template>
  <div>
    <MainHeader />

    <MainBanner2 />

    <!-- Services -->
    <div
      class="py-8 bg-center bg-no-repeat bg-cover bg-opacity-80"
      style="background-image: url('/images/bg-package.png')"
      id="packages"
    >
      <div class="container">
        <div>
          <h1 class="text-2xl font-semibold text-center text-gray-800">
            TIME Fibre <span class="text-time-pink">Packages</span>
          </h1>

          <div class="pt-5 md:pt-8 md:flex md:justify-center">
            <!-- tab -->
            <div class="px-1">
              <div
                v-bind:class="[tab === 'tab-1' ? 'active' : '']"
                @click="tab = 'tab-1'"
                class="w-64 mx-auto mb-2 text-gray-700 border-2 cursor-pointer md:mb-0 md:mx-0 hover:bg-time-dark hover:text-white border-time-dark"
              >
                <h1 class="py-2 text-xl font-medium text-center">
                  TIME For <span class="font-bold">Home</span>
                </h1>
              </div>
            </div>
            <div class="px-1">
              <div
                v-bind:class="[tab === 'tab-2' ? 'active' : '']"
                @click="tab = 'tab-2'"
                class="w-64 mx-auto text-gray-700 border-2 cursor-pointer md:mx-0 hover:bg-time-dark hover:text-white border-time-dark"
              >
                <h1 class="py-2 text-xl font-medium text-center">
                  Time For <span class="font-bold">Business</span>
                </h1>
              </div>
            </div>
          </div>

          <!-- modal -->
          <div class="pt-4 lg:max-w-4xl lg:mx-auto">
            <div v-show="tab == 'tab-1'" class="md:flex">
              <div class="pb-3 md:w-2/3 md:mx-auto">
                <div class="w-3/4 mx-auto rounded-lg md:w-full bg-time-brown">
                  <div class="py-10 text-center">
                    <h1 class="pb-1 text-xl font-semibold text-time-pink">
                      100Mbps
                    </h1>
                    <h1 class="pb-3 text-sm font-semibold text-gray-800">
                      Home Broadband (Unlimited)
                    </h1>
                    <h1 class="text-5xl font-bold leading-none">
                      <span
                        class="inline-block text-xl font-semibold align-text-top"
                        >RM</span
                      >99<span
                        class="inline-block text-xl font-semibold align-text-bottom"
                        >/month</span
                      >
                    </h1>
                    <h1 class="pt-6 text-lg font-medium text-gray-900">
                      24-month contract*
                    </h1>
                    <div class="pt-6 md:h-28">
                      <!-- <h1
                        class="w-48 py-2 mx-auto text-base font-semibold leading-tight md:text-sm bg-time-yellow"
                      >
                        <span class="text-time-pink">RM100 OFF</span> YOUR FIRST
                        BILL!
                      </h1>
                      <h1 class="pt-5 text-lg font-semibold text-time-pink">
                        - Online Exclusive -
                      </h1> -->
                    </div>
                    <div class="pt-8">
                      <a
                        href="#"
                        v-scroll-to="{ el: '#element', duration: 1500 }"
                      >
                        <h1
                          class="w-40 py-2 mx-auto text-lg text-white uppercase bg-time-pink rounded-xl"
                        >
                          I want this
                        </h1>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative pb-3 md:w-2/3 md:mx-auto">
                <div
                  class="w-3/4 mx-auto rounded-lg md:w-full bg-time-pink best-seller"
                >
                  <div class="py-10 text-center">
                    <h1 class="pb-1 text-xl font-semibold text-gray-900">
                      500Mbps
                    </h1>
                    <h1 class="pb-3 text-sm font-semibold text-gray-800">
                      Home Broadband (Unlimited)
                    </h1>
                    <h1 class="text-5xl font-bold leading-none text-white">
                      <span
                        class="inline-block text-xl font-semibold align-text-top"
                        >RM</span
                      >139<span
                        class="inline-block text-xl font-semibold text-gray-900 align-text-bottom"
                        >/month</span
                      >
                    </h1>
                    <h1 class="pt-6 text-lg font-medium text-gray-900">
                      24-month contract*
                    </h1>
                    <div class="pt-6 md:h-28">
                      <h1
                        class="py-2 mx-auto text-base font-semibold leading-tight md:text-sm w-52 bg-time-yellow"
                      >
                        <span class="text-time-pink">RM10 OFF</span> x 24 MONTHS
                      </h1>
                    </div>
                    <!-- <h1 class="pt-5 text-2xl font-semibold text-time-pink">- Online Exclusive -</h1> -->
                    <div class="pt-8">
                      <a
                        href="#"
                        v-scroll-to="{ el: '#element', duration: 1500 }"
                      >
                        <h1
                          class="w-40 py-2 mx-auto text-lg text-white uppercase bg-gray-900 rounded-xl"
                        >
                          I want this
                        </h1>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pb-3 md:w-2/3 md:mx-auto">
                <div class="w-3/4 mx-auto rounded-lg md:w-full bg-time-brown">
                  <div class="py-10 text-center">
                    <h1 class="pb-1 text-xl font-semibold text-time-pink">
                      1Gbps
                    </h1>
                    <h1 class="pb-3 text-sm font-semibold text-gray-800">
                      Home Broadband (Unlimited)
                    </h1>
                    <h1 class="text-5xl font-bold leading-none">
                      <span
                        class="inline-block text-xl font-semibold align-text-top"
                        >RM</span
                      >199<span
                        class="inline-block text-xl font-semibold align-text-bottom"
                        >/month</span
                      >
                    </h1>
                    <h1 class="pt-6 text-lg font-medium text-gray-900">
                      24-month contract*
                    </h1>
                    <div class="pt-6 md:h-28">
                      <h1
                        class="w-48 py-2 mx-auto text-base font-semibold leading-tight md:text-sm bg-time-yellow"
                      >
                        <span class="text-time-pink">RM10 OFF</span> X 24 MONTHS
                      </h1>
                    </div>
                    <div class="pt-8">
                      <a
                        href="#"
                        v-scroll-to="{ el: '#element', duration: 1500 }"
                      >
                        <h1
                          class="w-40 py-2 mx-auto text-lg text-white uppercase bg-time-pink rounded-xl"
                        >
                          I want this
                        </h1>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-show="tab == 'tab-2'" class="md:flex">
              <div class="pb-3 md:w-2/3 md:mx-auto md:px-1">
                <div
                  class="w-3/4 mx-auto bg-gray-900 rounded-t-lg md:mx-0 md:w-full"
                >
                  <div
                    class="py-3 text-xl text-center text-white border-b-2 border-white rounded-t-lg bg-time-pink"
                  >
                    <h1>Retail</h1>
                  </div>
                  <div class="py-10 text-center">
                    <h1 class="pb-4 text-xl font-semibold text-white">
                      100Mbps
                    </h1>
                    <h1
                      class="pb-4 text-lg font-semibold text-gray-600 line-through"
                    >
                      RM188/month
                    </h1>
                    <h1 class="text-5xl font-bold leading-none text-time-pink">
                      <span
                        class="inline-block text-xl font-semibold align-text-top"
                        >RM</span
                      >138<span
                        class="inline-block text-xl font-semibold align-text-bottom"
                        >/month</span
                      >
                    </h1>
                    <h1 class="pt-6 text-lg font-medium text-white">
                      24-month contract*
                    </h1>
                    <div class="pt-6 lg:h-20">
                      <h1
                        class="py-2 mx-auto text-base font-semibold leading-tight w-52 bg-time-yellow"
                      >
                        <span class="text-time-pink">Discount RM50</span> for 24
                        months
                      </h1>
                    </div>
                    <div class="pt-8">
                      <a
                        href="#"
                        v-scroll-to="{ el: '#element', duration: 1500 }"
                      >
                        <h1
                          class="w-40 py-2 mx-auto text-lg text-white uppercase bg-time-pink rounded-xl"
                        >
                          I want this
                        </h1>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pb-3 md:w-2/3 md:mx-auto md:px-1">
                <div
                  class="w-3/4 mx-auto bg-gray-900 rounded-t-lg md:mx-0 md:w-full"
                >
                  <div
                    class="py-3 text-xl text-center text-white border-b-2 border-white rounded-t-lg bg-time-pink"
                  >
                    <h1>Office</h1>
                  </div>
                  <div class="py-10 text-center">
                    <h1 class="pb-4 text-xl font-semibold text-white">
                      100Mbps
                    </h1>
                    <h1
                      class="pb-4 text-lg font-semibold text-gray-600 line-through"
                    >
                      RM248/month
                    </h1>
                    <h1 class="text-5xl font-bold leading-none text-time-pink">
                      <span
                        class="inline-block text-xl font-semibold align-text-top"
                        >RM</span
                      >218<span
                        class="inline-block text-xl font-semibold align-text-bottom"
                        >/month</span
                      >
                    </h1>
                    <h1 class="pt-6 text-lg font-medium text-white">
                      24-month contract*
                    </h1>
                    <div class="pt-6 lg:h-20">
                      <h1
                        class="py-2 mx-auto text-base font-semibold leading-tight w-52 bg-time-yellow"
                      >
                        <span class="text-time-pink">Discount RM30</span> for 24
                        months
                      </h1>
                    </div>
                    <div class="pt-8">
                      <a
                        href="#"
                        v-scroll-to="{ el: '#element', duration: 1500 }"
                      >
                        <h1
                          class="w-40 py-2 mx-auto text-lg text-white uppercase bg-time-pink rounded-xl"
                        >
                          I want this
                        </h1>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pb-3 md:w-2/3 md:mx-auto md:px-1">
                <div
                  class="w-3/4 mx-auto bg-gray-900 rounded-t-lg md:mx-0 md:w-full"
                >
                  <div
                    class="py-3 text-xl text-center text-white border-b-2 border-white rounded-t-lg bg-time-pink"
                  >
                    <h1>Office</h1>
                  </div>
                  <div class="py-10 text-center">
                    <h1 class="pb-4 text-xl font-semibold text-white">
                      300Mbps
                    </h1>
                    <h1
                      class="pb-4 text-lg font-semibold text-gray-600 line-through"
                    >
                      RM438/month
                    </h1>
                    <h1 class="text-5xl font-bold leading-none text-time-pink">
                      <span
                        class="inline-block text-xl font-semibold align-text-top"
                        >RM</span
                      >258<span
                        class="inline-block text-xl font-semibold align-text-bottom"
                        >/month</span
                      >
                    </h1>
                    <h1 class="pt-6 text-lg font-medium text-white">
                      24-month contract*
                    </h1>
                    <div class="pt-6 lg:h-20">
                      <h1
                        class="py-2 mx-auto text-base font-semibold leading-tight w-52 bg-time-yellow"
                      >
                        <span class="text-time-pink">Discount RM180</span> for
                        24 months
                      </h1>
                    </div>
                    <div class="pt-8">
                      <a
                        href="#"
                        v-scroll-to="{ el: '#element', duration: 1500 }"
                      >
                        <h1
                          class="w-40 py-2 mx-auto text-lg text-white uppercase bg-time-pink rounded-xl"
                        >
                          I want this
                        </h1>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- high-speed -->
    <div class="py-10 bg-time-lite">
      <div class="container">
        <div class="leading-tight text-center">
          <h1 class="pb-2 text-xl font-semibold text-center text-time-pink">
            Enjoy consistent high-speed Internet without the lag
          </h1>
          <p class="text-xs text-gray-700">
            Don't just take our word for it. Check out our awards and
            accreditations.
          </p>
        </div>

        <div class="pt-6 md:flex lg:justify-center lg:max-w-4xl lg:mx-auto">
          <div
            class="pb-3 text-center md:text-left md:pb-0 md:w-1/2 lg:px-6 lg:w-1/2"
          >
            <div class="lg:flex lg:items-center">
              <img
                src="/images/ookla.png"
                alt="Ookla"
                class="w-1/2 mx-auto lg:w-28 lg:order-2"
              />
              <div class="lg:w-72">
                <h1
                  class="pb-2 text-lg font-semibold leading-tight text-gray-800"
                >
                  Malaysia’s Most Consistent <br />
                  Broadband Provider
                </h1>
                <p class="text-xs font-light text-gray-700">
                  Based on analysis by Ookla® of Speedtest Intelligence® data
                  for Q1 2021. Ookla trademarks used under license and reprinted
                  with permission.
                </p>
              </div>
            </div>
          </div>
          <div class="text-center md:text-left md:w-1/2 lg:px-6 lg:w-1/2">
            <div class="lg:flex lg:items-center">
              <img
                src="/images/mcmc.png"
                alt="MCMC"
                class="w-1/2 mx-auto lg:w-28 lg:order-2"
              />
              <div class="lg:w-72">
                <h1
                  class="pb-2 font-semibold leading-tight text-gray-800 textext-lg"
                >
                  MCMC Network Performance Report 2018 & 2019
                </h1>
                <ul
                  class="text-xs font-light text-gray-700 list-disc list-inside"
                >
                  <li>Lowest Latency</li>
                  <li>0% Packet Loss</li>
                  <li>Ping ≤ 85 ms at 100% of the time</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-10">
          <a
            href="#"
            id="btn-enquire-highspeed"
            v-scroll-to="{ el: '#element', duration: 1500 }"
          >
            <h1
              class="py-2 mx-auto text-center text-white transition duration-300 ease-in-out rounded-lg w-52 bg-time-pink"
            >
              Apply Now
            </h1>
          </a>
        </div>
      </div>
    </div>

    <!-- Installation -->
    <!-- <div class="py-8 bg-center bg-no-repeat bg-cover" style="background-image:url('/images/bg-tech.jpg')"> -->
    <div id="install" class="py-8 bg-gray-50">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-800">
          Installation Is As <br class="lg:hidden" /><span
            class="text-time-pink"
            >Easy As 123</span
          >
        </h1>

        <div class="lg:flex">
          <div
            class="pt-8 idvideo md:max-w-2xl md:mx-auto lg:mx-0 lg:w-1/2 lg:order-2"
          >
            <youtube :video-id="videoId" ref="youtube"></youtube>
          </div>
          <div class="pt-6 lg:pt-8 md:flex md:flex-wrap lg:w-1/2">
            <div
              v-for="(item, i) in steps"
              :key="i"
              class="pb-3 lg:pb-0 md:w-1/3 lg:w-full lg:px-1"
            >
              <div class="lg:flex lg:flex-wrap lg:items-center">
                <div class="lg:w-1/5">
                  <img
                    :src="item.image"
                    :alt="item.alt"
                    class="w-1/2 mx-auto lg:w-20"
                  />
                </div>
                <div class="pt-2 text-center lg:pt-0 lg:text-left lg:w-4/5">
                  <h1 class="text-base font-bold text-time-pink">
                    {{ item.alt }}
                  </h1>
                  <p class="pt-2 text-xs leading-tight text-gray-900">
                    {{ item.p }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="px-2 py-4 mt-3 text-sm font-light leading-tight text-center text-white lg:px-3 lg:py-6 lg:mt-6 bg-time-pink"
        >
          <h1 class="pb-3">
            The process of application will just take 1 to 2 days and about 5 to
            7 days to arrange the installation. Depending on the complexity of
            the layout of your condo, the entire process of installing the fibre
            should take 45 minutes to 2 hours.
          </h1>
          <h1 class="pb-3">
            The installation process is free for Malaysian Citizens. But foreign
            citizens will need to pay a deposit of RM500.
          </h1>
          <h1 class="pb-3">
            We will also assist you every step of the way with our customer
            service, ensuring that you get what you paid for when it comes to
            amazing fibre optic speeds.
          </h1>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div
      class="py-8 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-tech.jpg')"
    >
      <div class="container">
        <div>
          <h1 class="pb-8 text-2xl font-semibold text-center text-gray-800">
            Why TIME Fibre <br class="lg:hidden" /><span class="text-time-pink"
              >Is Your Choice?</span
            >
          </h1>

          <div
            class="md:pt-5 md:flex md:flex-wrap md:justify-center lg:max-w-4xl lg:mx-auto"
          >
            <div
              v-for="(item, i) in choose"
              :key="i"
              class="pb-4 md:w-1/3 lg:w-1/5 md:px-2 lg:px-1"
            >
              <img
                :src="item.image"
                :alt="item.alt"
                class="w-1/3 mx-auto lg:w-1/2"
              />
              <h1
                class="text-xl font-medium text-center lg:text-sm text-time-pink"
              >
                {{ item.alt }}
              </h1>
              <p
                class="pt-4 text-sm leading-tight text-center text-gray-900 lg:text-xs"
              >
                {{ item.p }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- faq -->
    <div id="faq" class="py-8">
      <div class="container">
        <h1 class="pb-8 text-2xl font-semibold text-center text-gray-800">
          Frequently Asked <span class="text-time-pink">Questions</span>
        </h1>
        <div class="lg:w-2/3 lg:mx-auto">
          <Accordion :faq="faq" :focus="false" class="pt-4" />
        </div>
      </div>
    </div>

    <!-- enquire form -->
    <div id="element" class="py-8 bg-gray-100">
      <div class="container">
        <div class="pb-8 text-center">
          <h1 class="pb-3 text-2xl font-semibold leading-tight text-time-pink">
            We've got what you need. <br />
            Let us help your business stay one step ahead.
          </h1>
          <p class="text-sm font-normal text-gray-900">
            Leave your details below and we will call you.
          </p>
        </div>
        <div id="enquire" class="lg:max-w-3xl lg:mx-auto">
          <!-- feedback.activamedia.com.sg script begins here --><iframe
            allowTransparency="true"
            style="min-height: 1700px; height: inherit; overflow: auto"
            width="100%"
            id="contactform123"
            name="contactform123"
            marginwidth="0"
            marginheight="0"
            frameborder="0"
            src="https://feedback.activamedia.com.sg/my-contact-form-5914849.html"
            ><p>
              Your browser does not support iframes. The contact form cannot be
              displayed. Please use another contact method (phone, fax etc)
            </p></iframe
          ><!-- feedback.activamedia.com.sg script ends here -->
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer id="contact" class="py-10">
      <div class="container">
        <div class="md:flex md:justify-center">
          <div class="pb-6 md:w-1/4">
            <h1
              class="pb-5 text-lg font-bold text-center text-gray-700 uppercase md:text-left md:text-xs lg:text-sm"
            >
              Sales
            </h1>
            <img
              src="/images/footer-logo.png"
              alt="Vpay Partner Sdn Bhd"
              class="w-1/2 mx-auto lg:mx-0 md:w-full lg:w-32"
            />
            <div class="pt-2">
              <h1 class="text-xs text-center text-gray-800 lg:text-left">
                Primus Network Media <br />
                A-2F-42, IOI Boulevard, Jalan Kenari 5,<br />
                Bandar Puchong Jaya 47100, Selangor
              </h1>
            </div>
          </div>
          <!-- sitemap -->
          <div class="pb-6 md:w-1/4 md:pl-2">
            <h1
              class="pb-5 text-lg font-bold text-center text-gray-700 uppercase md:text-left md:text-xs lg:text-sm"
            >
              Sitemap
            </h1>
            <ul class="w-1/3 mx-auto text-sm font-light md:mx-0 md:w-4/5">
              <li>
                <i class="pr-1 fas fa-angle-right text-time-pink"></i>
                <a href="#" v-scroll-to="{ el: '#packages', duration: 1500 }">
                  Packages
                </a>
              </li>
              <li>
                <i class="pr-1 fas fa-angle-right text-time-pink"></i>
                <a href="#" v-scroll-to="{ el: '#install', duration: 1500 }">
                  Installation
                </a>
              </li>
              <li>
                <i class="pr-1 fas fa-angle-right text-time-pink"></i>
                <a href="#" v-scroll-to="{ el: '#faq', duration: 1500 }">
                  FAQ
                </a>
              </li>
              <li>
                <i class="pr-1 fas fa-angle-right text-time-pink"></i>
                <a href="#" v-scroll-to="{ el: '#contact', duration: 1500 }">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <!-- call us -->
          <!-- <div class="pb-6 md:w-1/4">
            <h1
              class="pb-5 text-lg font-bold text-center text-gray-700 uppercase md:text-left md:text-xs lg:text-sm"
            >
              Technical Issues
            </h1>
            <div class="text-center md:text-left">
              <h1 class="text-2xl font-semibold text-time-pink">
                <a href="tel:1800181818" id="call-btn-bottom"> 1800 18 1818 </a>
              </h1>
            </div>
          </div> -->

          <!-- complain -->
          <div class="md:w-1/4">
            <div class="text-center md:text-left">
              <h1
                class="pb-5 text-lg font-bold text-center text-gray-700 uppercase md:text-left md:text-xs lg:text-sm"
              >
                REDRESS YOUR COMPLAINT
              </h1>
              <img
                src="/images/Unresolved.png"
                alt="Unresolved"
                class="mx-auto lg:mx-0"
              />
              <p class="pt-3 text-xs font-light text-gray-800">
                Redress your complaints at the Consumer Forum Malaysia (CFM)
                <a
                  href="https://complaint.cfm.my/"
                  target="_blank"
                  class="text-time-pink"
                  @click.stop
                  >www.complaint.cfm.org.my</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <Copyright />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Accordion from "@/components/Accordion.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainBanner2 from "@/components/MainBanner2.vue";
import Copyright from "@/components/Copyright.vue";

export default {
  components: {
    Accordion,
    MainHeader,
    MainBanner2,
    Copyright,
  },
  data() {
    return {
      tab: "tab-1",
      videoId: "cyf2O8V-LHU",
      steps: [
        {
          image: "/images/icon-1.png",
          alt: "1. Pick A Date",
          p: "Choose your installation date when you sign up.",
        },
        {
          image: "/images/icon-2.png",
          alt: "2. Sit Back, Relax",
          p:
            "Wait for our installer to confirm the appointment and assist with connection.",
        },
        {
          image: "/images/icon-3.png",
          alt: "3. Connection Test",
          p:
            "Our installer will test your connection and answer all your questions.",
        },
      ],
      choose: [
        {
          image: "/images/icon-why-time-01.png",
          alt: "Fastest Speeds",
          p:
            "We've got speeds for every need - up to 1Gbps, the fastest in Malaysia.",
        },
        {
          image: "/images/icon-no-lock-01.png",
          alt: "Best Value",
          p:
            "Freedom to choose the plan that suits you the most from as low as RM99/month.",
        },
        {
          image: "/images/icon-why-time-03b.png",
          alt: "Quality Network",
          p:
            "Our wholly-owned 100% fibre network ensures you're consistently connected.",
        },
        {
          image: "/images/icon-why-time-04b.png",
          alt: "Seamless Connectivity",
          p:
            "Enjoy a seamless experience on multiple devices everywhere with whole home wireless coverage",
        },
        {
          image: "/images/icon-why-time-05b.png",
          alt: "Expert Support",
          p:
            "Our Tech Ninjas have got your back with 24/7 support over phone, chat or handy digital tools.",
        },
      ],
      faq: [
        {
          q: "What are the benefits of using Time?",
          a:
            "We at Time Internet Malaysia are prepared to give you the fastest and most stable Internet networks in Malaysia. Our Time Internet Plan Malaysia can go up to 1Gbps in speed and are 100% fibre; ensuring an incredibly fast and stable Internet connection for all users. What’s more, we also guarantee seamless Wi-Fi connectivity with the implementation of our Wi-Fi Booster, and promise expert installation of our Internet packages into your home. Delivering the Greatest Essential of Our Time",
        },
        {
          q: "What personal internet packages do you provide?",
          a:
            "At Time Internet Malaysia, we offer 3 different Time internet plans Malaysia that are each tailored accordingly to different needs. Our Time 100mbps plan for RM99/month is a standard for those who love to use the Internet, while our Time 500mbps plan for RM139/month is perfect for those who use more than one device. Finally, our bestselling RM199/month plan is ideal for anyone who wants or needs fast Internet.",
        },
        {
          q:
            "As a businessman, what can I get a Business Package WiFi plan in Malaysia? ",
          a:
            "We at Time Fibre offer 2 different packages (Retail plan for RM 138/month and Office plan for RM 218/month). Both packages are tailored for large businesses that run on intensive, complex processes, ensuring that all your online work practices are operating smoothly via a fast and reliable internet plan.",
        },
        {
          q: "How long will it take to install Time Broadband?",
          a:
            "Time Broadband Malaysia will take just 1 to 2 days to apply, and about 5 to 7 days to arrange the installation. The entire installation process will vary depending on the complexity of the layout of your condo, but on average it should take 45 minutes to 2 hours.",
        },
        {
          q: "Is the installation process free?",
          a:
            "The installation process for Time Broadband package Malaysia will be free for Malaysian Citizens. However, foreign citizens will be required to pay a deposit of RM500.",
        },
      ],
    };
  },
};
</script>
<style>
.active {
  background-color: #ea068c;
}
.active h1 {
  color: #ffffff;
}
.best-seller:before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  right: 15px;
  width: 65px;
  height: 100px;
  background-image: url("/images/best-seller.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
}
/* youtube width */
.idvideo iframe {
  width: 100%;
}

@media (min-width: 767px) {
  #enquire iframe {
    min-height: 1200px !important;
  }
}
@media (min-width: 1024px) {
  .custom {
    top: 66px;
  }
}
@media (min-width: 1280px) {
  /* #enquire iframe {
    min-height: 1250px !important;
  } */
}
</style>
